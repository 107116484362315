// 学校管理
import { Layout, NoLayout } from '../constant-components.js';

export default {
  path: '/school',
  redirect: '',
  component: Layout,
  meta: {
    title: ''
  },
 
children: [
  {
    path: 'index',
    component: NoLayout,
    meta: {
      title: '学校列表',
      associateMenu: 'school/index'
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/school/index.vue'),
        meta: {
          title: ''
        }
      },
      {
        path: 'addedit',
        component: () => import('@/views/school/addedit.vue'),
        meta: {
          title: '新增-编辑'
        },
      },
      {
        path: 'distribute',
        component: () => import('@/views/school/distribute.vue'),
        meta: {
          title: '分发'
        },
      },
      {
        path: 'operation',
        component: () => import('@/views/school/operation.vue'),
        meta: {
          title: '操作日志'
        },
      }
    ]
  }
]
    
 
};